import React, { useEffect } from "react";
import { useMyContext } from "../context/MyContext";
import Footer from "../footer/Footer";
import Dropdown from "../header/Dropdown";
import Header from "../header/Header";
import CustomGallary from "./custom gallary/CustomGallary";
import GallaryLanding from "./gallary landing/GallaryLanding";

const MyImg = () => {
  const scrollToPosition = (top = 0) => {
    try {
      /**
       * Latest API
       */
      window.scroll({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    } catch (_) {
      /**
       * Fallback
       */
      window.scrollTo(0, top);
    }
  };

  useEffect(() => {
    scrollToPosition();
  }, []);

  const { isOpen } = useMyContext();

  return (
    <>
      <Header />
      {isOpen && <Dropdown />}
      <CustomGallary />
      <Footer />
    </>
  );
};

export default MyImg;
