import React from "react";
import "./SmallAboutMe.css";
import Welcome from "../assets/welcome.jpg";
const SmallAboutMe = () => {
  return (
    <div className="small-about-me">
      <div className="container small-container">
        <div className="small-div">
          <h5>ستارك للتعبئة والتغليف</h5>
          <p className="small-p-title">مرحبًا بزوار ستارك للتعبئة والتغليف</p>
          <p className="small-p-welcome">
            في البداية يسعدنا في مجلس إدارة ستارك بأن نرحب بالسادة الزوار ،
            والمساهمين والمشاركين الكرام فنحن هنا اليوم لنمد لكم جسر التواصل بين
            ستارك وبين مساهميها وعملائها.ولكي نؤكد لكل عملائنا بأنه مهما واجهنا
            ومر علينا من تغييرات متلاحقة تجتاح العالم، سنحافظ على عقيدتنا
            الراسخة المعهودة، التي تؤمن بأن رأس المال البشري هو قيم أصول
            الشركة.حيث تحظى ستارك بفريق عمل لديه من الخبرة والمهارة مايكفي ويفيض
            لنيل ثقة واحترام العملاء في شتى بلدان العالم ، اليوم تعمل مجموعاتنا
            الصناعية بقوة إنتاج تعادل 20 ضعف قوتها التي بدأت بها منذ تأسيسها،
            ومستعدون لبذل كل ما يلزم في سبيل الأرتقاء أكثر فأكثر لإشباع حاجات
            ورغبات مستهلكينا
          </p>
          <p className="small-p-last">
            وفي النهاية نود أن نؤكد تشرفنا بزيارتك لموقعنا ومتابعة المنتجات
            المتاحة فيه لدينا الثقة بأنك سوف تجد فية كل شىء تحتاجه من منتجات
            التعبئة والتغليف ولأي استفسارات أخري تواصل معنا ، سوف يقوم أحد
            موظفينا بالرد علي استفسارك .
          </p>
        </div>
        <div className="small-img">
          <img src={Welcome} alt="Welcome" />
        </div>
      </div>
    </div>
  );
};

export default SmallAboutMe;
