import React, { useEffect } from "react";
import { useMyContext } from "../context/MyContext";
import FirstFeat from "../feats/FirstFeat";
import Footer from "../footer/Footer";
import Dropdown from "../header/Dropdown";
import Header from "../header/Header";
import Landing from "../Landing/Landing";
import SmallVideos from "../our work/SmallVideos";
import AboutCompany from "./about company/AboutCompany";
import AboutLanding from "./about landing/AboutLanding";
import OurMission from "./our mission/OurMission";
import SmallAboutMe from "./SmallAboutMe";

const AboutUs = () => {
  const { isOpen } = useMyContext();
  const scrollToPosition = (top = 0) => {
    try {
      /**
       * Latest API
       */
      window.scroll({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    } catch (_) {
      /**
       * Fallback
       */
      window.scrollTo(0, top);
    }
  };

  useEffect(() => {
    scrollToPosition();
  }, []);
  return (
    <>
      <Header />
      {isOpen && <Dropdown />}
      <AboutLanding />
      <SmallAboutMe />
      <AboutCompany />
      <OurMission />
      <SmallVideos />
      <FirstFeat />
      <Footer />
    </>
  );
};

export default AboutUs;
