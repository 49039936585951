import React from "react";

import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import "./ContactContent.css";
import { Link } from "react-router-dom";
const ContactContent = () => {
  return (
    <div className="ccc-container">
      <div className="contactHeader">
        <h1> اتصل بنا</h1>
        <div className="contact-contact">
          <div className="contact-loaction">
            <GoLocation />
            <Link className="contact-link" to="/contact-us">
              الفرع الرئيسي: العاشر من رمضان بجوار الاردنية, مصر
            </Link>
          </div>
          <div className="contact-loaction">
            <AiOutlineMail />
            <a className="contact-link" href="mailto:starkPacking@gmail.com">
              starkPacking@gmail.com
            </a>
          </div>
          <div className="contact-loaction">
            <BiPhone />
            <a className="contact-link" href="tel:+201026677668">
              201222600817+
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactContent;
