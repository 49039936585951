import React from "react";
import { useForm, ValidationError } from "@formspree/react";

import "./Form.css";
const Form = () => {
  const [state, handleSubmit] = useForm("mbjwwayz");
  if (state.succeeded) {
    console.log("succeeded");
  }

  return (
    <div className="my-contact-form" onSubmit={handleSubmit}>
      <form
        className="myForm"
        action="https://formspree.io/f/mbjwwayz"
        method="POST"
      >
        <div>
          <label htmlFor="full-name">الاسم بالكامل</label>
          <input
            type="text"
            name="name"
            id="full-name"
            placeholder="ادخل اسمك بالكامل"
            required
          />
        </div>
        <div>
          <label htmlFor="email-address">بريدك الالكتروني</label>
          <input
            type="email"
            name="_replyto"
            id="email-address"
            placeholder="email@domain.com"
            required
          />
        </div>
        <div>
          <label htmlFor="message">رسالتك</label>
          <textarea
            rows="3"
            name="message"
            id="message"
            placeholder="اترك رسالتك هنا"
            required
          ></textarea>
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Form;
