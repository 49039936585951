import React from "react";

import "./ContactForm.css";
const ContactForm = () => {
  return (
    <div className="contact-from">
      <div className="contact-from-container">
        <h1>تواصل معنا</h1>
        <p>
          يمكنك الاتصال بنا في اي وقت يناسبك. متوفرين 24/7 عن طريق البريد
          الالكتروني. يمكنك أيضا استخدام نموذج الاتصال التالي للأتصال بنا. سنكون
          في قمة السعادة للاجابة على استفساراتك.
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
