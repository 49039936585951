import React from "react";
import "./Footer.css";

import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="my-footer">
      <div className="footer-container">
        <div className="myfooter-contact">
          <h3>اتصل بنا</h3>
          <div className="footer-loaction">
            <GoLocation />
            <Link className="footer-link" to="/">
              الفرع الرئيسي: العاشر من رمضان, مصر
            </Link>
          </div>
          <div className="footer-loaction">
            <AiOutlineMail />
            <a className="footer-link" href="mailto:starkPacking@gmail.com">
              starkPacking@gmail.com
            </a>
          </div>
          <div className="footer-loaction">
            <BiPhone />
            <a className="footer-link" href="tel:+201026677668">
              201222600817+
            </a>
          </div>
        </div>
        <div className="footer-list">
          <h3>روابط سريعة</h3>
          <ul className="myfooter-ul">
            <li className="myfooter-li">
              <Link to="/" className="myfooter-link">
                الرئيسيه
              </Link>
            </li>
            <li className="myfooter-li">
              <Link to="/products" className="myfooter-link">
                منتجاتنا
              </Link>
            </li>
            <li className="myfooter-li">
              <Link to="/about-us" className="myfooter-link">
                من نحن
              </Link>
            </li>

            <li className="myfooter-li">
              <Link to="/my-work" className="myfooter-link">
                أعمالنا
              </Link>
            </li>
            <li className="myfooter-li myfooter-last">
              <Link to="/contact-us" className="myfooter-link">
                اتصل بنا
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="mo-copyright">
        Developed by
        <a
          href="https://www.linkedin.com/in/mohamed-adel-07689321b/"
          target="_blank"
        >
          {" "}
          Mohamed Adel
        </a>
      </div>
    </footer>
  );
};

export default Footer;
