import React from "react";
import "./AboutLanding.css";

import AboutImg from "../../assets/about.png";
const AboutLanding = () => {
  return (
    <div className="about-landing">
      <img src={AboutImg} alt="landing" />
    </div>
  );
};

export default AboutLanding;
