import React from "react";
import "./SmallVideos.css";
const SmallVideos = () => {
  return (
    <div className="small-vid-contanier">
      <div className="small-vid-first">
        <h2>الإنتاج والجودة</h2>
        <a
          className="small-vid-btn"
          href="https://www.youtube.com/channel/UCxIT6N5HQzytgUmAnlxuoIw"
          target="_blank"
        >
          شاهد جميع الفيديوهات
        </a>
      </div>
      <div className="small-vid-second">
        <iframe
          src={`https://www.youtube.com/embed/7Hx-fVgsACQ`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        <iframe
          src={`https://www.youtube.com/embed/4UMDAIOA1gM`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        <iframe
          src={`https://www.youtube.com/embed/3Zphaj2pCvo`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
};

export default SmallVideos;
