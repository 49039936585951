import React, { useEffect } from "react";
import { useMyContext } from "../context/MyContext";
import Footer from "../footer/Footer";
import Dropdown from "../header/Dropdown";
import Header from "../header/Header";
import ContactForm from "./contact form/ContactForm";
import Form from "./contact form/Form";
import ContactContent from "./content/ContactContent";
import ContactLanding from "./landing/ContactLanding";

const ContactUs = () => {
  const { isOpen } = useMyContext();

  const scrollToPosition = (top = 0) => {
    try {
      /**
       * Latest API
       */
      window.scroll({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    } catch (_) {
      /**
       * Fallback
       */
      window.scrollTo(0, top);
    }
  };

  useEffect(() => {
    scrollToPosition();
  }, []);
  return (
    <>
      <Header />
      {isOpen && <Dropdown />}
      <ContactLanding />
      <ContactContent />
      <ContactForm />
      <Form />
      <Footer />
    </>
  );
};

export default ContactUs;
