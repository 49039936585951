import React from "react";

import "./OurMission.css";
const OurMission = () => {
  return (
    <div className="mission-all">
      <div className="mission-container">
        <div className="mission-feat">
          <h5> مهمتنا</h5>
          <p>
            أن نكون ممولاً مبدعًا للتعبئة . ننصت وندقق بالتفاصيل ونعمل بشكل
            تكاملي مع مقدمي الخدمات الغذائية، للوصول لحلول تعبئة وتغليف ذات
            قيمة، تُمكن لعملائنا التميز وسط منافسيهم، وكسب ثقة وود جمهورهم.
          </p>
        </div>
        <div className="mission-feat">
          <h5>الرؤية</h5>
          <p>
            أن نبني قاعدة عملاء مستدامة عن طريق توفير خدمات ممتازة في كل مشروع
            نقوم به وأن نصبح أفضل جهة لتوفير الحلول لعملائنا وننشئ منصات جديدة و
            نخلق فرصا للتواصل في مصر و دول الشرق الاوسط
          </p>
        </div>
        <div className="mission-feat">
          <h5> الآستراتيجية</h5>
          <p>
            تتخذ ستارك للتغليف رؤية مصر 2030 خطة لها، وتمثل لها أهمية قصوى، لذلك
            تسعى ستارك لتنمية الموارد المتاحة والمتخصصة التي نحتاجها في
            صناعتنا،لضمان تحقيق الشركة معدلات عالية من النمو على المدى البعيد،
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
