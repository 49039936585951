import React from "react";
import "./FirstFeat.css";
import { GoVerified } from "react-icons/go";
import { ImUsers } from "react-icons/im";
import { BsHeadphones } from "react-icons/bs";
const FirstFeat = () => {
  return (
    <div className="first-all">
      <div className="first-container">
        <div className="first-feat">
          <GoVerified className="first-icon" />
          <h5>حاصلين على</h5>
          <p>شهادات خبره معتمدة</p>
        </div>
        <div className="first-feat">
          <ImUsers className="first-icon" />
          <h5>العملاء: 200+</h5>
          <p>عملاء مرضيين 100% </p>
        </div>
        <div className="first-feat">
          <BsHeadphones className="first-icon" />
          <h5>دعم فني متوفر 24/7</h5>
          <p>نقدر نساعدك أونلاين</p>
        </div>
      </div>
    </div>
  );
};

export default FirstFeat;
