import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import "./App.css";
import AboutUs from "./about me/AboutUs";
import MyImg from "./my gallary/MyImg";
import ContactUs from "./contact us/ContactUs";
import OurProducts from "./products/OurProducts";
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/my-work" element={<MyImg />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/products" element={<OurProducts />} />
      <Route path="/products/:any" element={<OurProducts />} />
    </Routes>
  );
};

export default App;
