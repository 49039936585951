import React from "react";
import ContactimgLanding from "../../assets/contact.png";

import "./ContactLanding.css";
const ContactLanding = () => {
  return (
    <div className="contact-landing">
      <img src={ContactimgLanding} alt="contact" />
    </div>
  );
};

export default ContactLanding;
