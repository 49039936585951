import React, { useEffect } from "react";
import SmallAboutMe from "../about me/SmallAboutMe";
import { useMyContext } from "../context/MyContext";
import FirstFeat from "../feats/FirstFeat";
import Footer from "../footer/Footer";
import Dropdown from "../header/Dropdown";
import Header from "../header/Header";
import Landing from "../Landing/Landing";
import SmallVideos from "../our work/SmallVideos";
import ProductsCategory from "../productsCate/ProductsCategory";

const Home = () => {
  const { isOpen } = useMyContext();
  const scrollToPosition = (top = 0) => {
    try {
      /**
       * Latest API
       */
      window.scroll({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    } catch (_) {
      /**
       * Fallback
       */
      window.scrollTo(0, top);
    }
  };

  useEffect(() => {
    scrollToPosition();
  }, []);
  return (
    <>
      <Header />
      {isOpen && <Dropdown />}
      <Landing />
      <SmallAboutMe />
      <ProductsCategory />
      <SmallVideos />
      <FirstFeat />
      <Footer />
    </>
  );
};

export default Home;
