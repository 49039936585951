import React from "react";
import { Link } from "react-router-dom";
import { useMyContext } from "../context/MyContext";
import "./Dropdown.css";
const Dropdown = () => {
  const { isOpen, setOpen } = useMyContext();
  console.log("dropdown");
  return (
    <div className="dropdown">
      <ul className="nav-list-mob">
        <li className="nav-list-li-mob">
          <Link
            to="/"
            className="nav-list-link-mob"
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            الرئيسيه
          </Link>
        </li>
        <li className="nav-list-li-mob">
          <Link
            to="/products"
            className="nav-list-link-mob"
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            منتجاتنا
          </Link>
        </li>
        <li className="nav-list-li-mob">
          <Link
            to="/about-us"
            onClick={() => {
              setOpen(!isOpen);
            }}
            className="nav-list-link-mob"
          >
            من نحن
          </Link>
        </li>

        <li className="nav-list-li-mob">
          <Link
            to="/my-work"
            className="nav-list-link-mob"
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            أعمالنا
          </Link>
        </li>
        <li className="nav-list-li-mob">
          <Link
            to="/contact-us"
            className="nav-list-link-mob"
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            اتصل بنا
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Dropdown;
