const IMGES = {
  1: require("./1.jpg"),
  2: require("./2.jpg"),
  3: require("./3.jpg"),
  4: require("./4.jpg"),
  5: require("./5.jpg"),
  6: require("./6.jpg"),
  7: require("./7.jpg"),
  8: require("./8.jpg"),
  9: require("./9.jpg"),
  10: require("./10.jpg"),
  11: require("./11.jpg"),
  12: require("./12.jpg"),
  13: require("./13.jpg"),
  14: require("./14.jpg"),
  15: require("./15.jpg"),
  16: require("./16.jpg"),
  17: require("./17.jpg"),
  18: require("./18.jpg"),
  19: require("./19.jpg"),
  20: require("./20.jpg"),
  21: require("./21.jpg"),
  22: require("./22.jpg"),
  23: require("./23.jpg"),
  24: require("./24.jpg"),
  25: require("./25.jpg"),
  26: require("./26.jpg"),
  27: require("./27.jpg"),
  28: require("./28.jpg"),
  29: require("./29.jpg"),
  30: require("./30.jpg"),
  31: require("./31.jpg"),
  32: require("./32.jpg"),
  33: require("./33.jpg"),
  34: require("./34.jpg"),
  35: require("./35.jpg"),
  36: require("./36.jpg"),
  37: require("./37.jpg"),
  38: require("./38.jpg"),
  39: require("./39.jpg"),
  40: require("./40.jpg"),
  41: require("./41.jpg"),
  42: require("./42.jpg"),
  43: require("./43.jpg"),
  44: require("./44.jpg"),
  45: require("./45.jpg"),
  46: require("./46.jpg"),
  47: require("./47.jpg"),
};

export default IMGES;
