import { createContext, useContext, useState } from "react";
import React from "react";

export const MyContext = createContext();
export const useMyContext = () => {
  return useContext(MyContext);
};
export const MyContextProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const value = {
    isOpen,
    setOpen,
  };

  return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
};
