import React, { useEffect } from "react";
import { useMyContext } from "../context/MyContext";
import Footer from "../footer/Footer";
import Dropdown from "../header/Dropdown";
import Header from "../header/Header";
import MyProducts from "./my products/MyProducts";

import "./OurProducts.css";
const OurProducts = () => {
  const { isOpen } = useMyContext();

  const scrollToPosition = (top = 0) => {
    try {
      /**
       * Latest API
       */
      window.scroll({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    } catch (_) {
      /**
       * Fallback
       */
      window.scrollTo(0, top);
    }
  };

  useEffect(() => {
    scrollToPosition();
  }, []);
  return (
    <>
      <Header />
      {isOpen && <Dropdown />}
      <MyProducts />
      <Footer />
    </>
  );
};

export default OurProducts;
