import React from "react";
import { Link } from "react-router-dom";

import Packing from "../assets/packing.png";
import Mixer from "../assets/mixer.jfif";
import "./ProductsCategory.css";
const ProductsCategory = () => {
  return (
    <div className="product-category-all">
      <div className="products-category">
        <h2>منتجاتنا</h2>
        <div className="products-category-container">
          <div className="product-category-fill">
            <div className="product-category-img">
              <img src={Mixer} alt="Mixer" />
            </div>
            <h1>منتجات الخلاطات والتانكات</h1>
            <Link
              className="product-category-link small-vid-btn"
              to="/products"
            >
              شاهد جميع المنتجات
            </Link>
          </div>
          <div className="product-category-tank">
            <div className="product-category-img">
              <img src={Packing} alt="Packing" />
            </div>
            <h1>منتجات التعبئه والتغليف</h1>
            <Link
              className="product-category-link small-vid-btn"
              to="/products"
            >
              شاهد جميع المنتجات
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCategory;
