import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./CustomGallary.css";
import IMGES from "../../assets/gallary/ImgIndex";
const CustomGallary = () => {
  const images = [];
  for (let index = 1; index <= Object.keys(IMGES).length; index++) {
    images.push({
      original: IMGES[index],
    });
  }

  return (
    <>
      <div className="ggg-container">
        <div className="workHeader">
          <h1> اعمالنا السابقة</h1>
        </div>
        <ImageGallery items={images} isRTL="true" autoPlay="true" />
      </div>
    </>
  );
};

export default CustomGallary;
