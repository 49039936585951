import React, { useEffect } from "react";

import "./MyProducts.css";

const data = [
  {
    key: 0,
    name: "ماكينه تعبئه سوائل في اكياس",
    src: "https://www.youtube.com/embed/7Hx-fVgsACQ",
    description: `
        جسم الماكينه ومكوناتها بالكامل مصنع من الاستالس ستيل ٣٠٤ المقاوم للصدا - 
        لحام الكيس ثلاثي - 
        عرض الرول من ٨سم حتي ٢٤ سم - 
        عرض الكيس من ٤ سم حتي ١٢ سم - 
        حجم التعبئه من ٥ ملي حتي ٥٠ ملي - 
        سرعه الماكينه الفعليه علي المنتج من ٤٠ الي ٥٠ كيس في الدقيقة - 
        تعمل الماكينه بالكامل بالنظام الميكانيكي وذلك لضمان دقه اللحام - 
        تعمل مضخه التعبيه بالهواء وذلك لضمان دقه الوزن وسهوله تغير الوزن وقله الأعطال - 
        يتم التحكم في جميع وظائف الماكينه عن طريق شاشه ٧ بوصه ألوان تعمل باللمس ماركه شنيدر - 
        جميع مكونات اللوحه الكهربية والشاشه  ماركه شنيدر - 
        جميع مكونات الماكينه اوربيه الصنع عدا موتور سحب الأكياس صناعه صيني وهو مكفول لمده عامين - 
        محركات إيطاليه قدره حصان ونصف - 
        يتم إرسال شنطه عده كامله وشنطه قطع غيار تكفي لمده اكثر من عام - 
        ضمان عام كامل علي الماكينه ضد اي عيب من عيوب الصناعة -  
        في حاله شحن الماكينه خارج جمهوريه مصر العربيه يتم تجهيز قرص صلب محمل عليه فديوهات لكيفيه تشغيل الماكينه و كيفيه حل الأعطال حين حدوثها - `,
  },
  {
    key: 1,
    name: "ماكينه تعبئه سوائل نصف أوتوماتيك ١ نزول ",
    src: "https://www.youtube.com/embed/4UMDAIOA1gM",
    description: `
    صناعه مصريه
    تعمل علي كافه أنواع السوائل اللزجة والخفيفة  - 
    تصلح لكافه الصناعات الغذائية والكيماوية   - 
    استالس ٣٠٤ أسباني بالكامل   - 
    مكونات اوربيه بالكامل   - 
    حجم التعبيه من ١٠٠ ملي الي ١٠٠٠ ملي   - 
    يمكن التحكم في كميه المنتج بسهوله عن طريق سنسور مغناطيسي بكل سهوله ويسر   - 
    تانك تعبئه المنتج ٥٠ لتر   - 
    سرعه الماكينة تختلف علي حسب الكميه المراد تعبئتها متوسط ١٠٠٠ عبوه في الساعه   - 
    الماكينه مزوده بوحده خدمه لفلتره الهواء  وتنقيته من الأبخرة للمحافظة علي مكونات الماكينة  - 
    يمكن التحكم في سرعه ضخ المنتج عن طريق منظم الهواء   - 
    الماكينة  مزوده بوحده منع التنقيط وذلك لعدم نزول المنتج بعد اتمام عمليه التعبئه   - 
    الماكينة  مزوده بأربع عجلات فرامل   - 
    يرسل مع الماكينة قطع غيار احتياطيه وهي (مجموعه جوانات كامله لكافه اجزاء الماكينة / سنسور مغناطيسي/فيوزات / مفتاح سلكتور / وصلات هواء متعددة )   - `,
  },
];
const data2 = [
  {
    id: 0,
    name: "خلاط بودر",
    description: `السعه من 100لتر حتي 1000 لتر - 
    الخلاط مصنع بالكامل من الاستالس ستيل 304 - 
    محبس استالس ستيل لخروج المنتج اسفل الخلاط - 
    الريشه حلزونيه مزدوجه للحصول علي اعلي كفائه للخلط - 
    باب الخلاط مصنع من الاستالس ستيل مزود بجوان لمنع خروج المنتج - 
    بساتم هيدروليك لرفع غطاء الخلاط - 
    حساسات لتعطيل الخلاط في حاله فتح الباب اثناء الخلط - 
    لوحه تحكم بها انفرتر للتحكم في سرعه الخلاط 220 / 380 علي حسب طلب العميل - 
    ارخص سعر واعلي جوده  - `,
  },
];
const MyProducts = () => {
  const scrollToPosition = (top = 0) => {
    try {
      /**
       * Latest API
       */
      window.scroll({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    } catch (_) {
      /**
       * Fallback
       */
      window.scrollTo(0, top);
    }
  };

  useEffect(() => {
    scrollToPosition();
  }, []);
  const listItem = data.map((item) => (
    <div key={item.id} className="product-card">
      <div className="product-description">
        <h1>{item.name}</h1>
        <p>{item.description}</p>
      </div>
      <iframe
        src={item.src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  ));
  const listItem2 = data2.map((item) => (
    <div key={item.id} className="product-card">
      <div className="product-description">
        <h1>{item.name}</h1>
        <p>{item.description}</p>
      </div>
      <iframe
        className="my-last-iframe"
        src={`https://www.youtube.com/embed/3Zphaj2pCvo`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  ));
  return (
    <div className="work-container">
      <div className="product-packing">
        <div className="product-header">
          <h1 className="main-header-packing">منتجات التعبئة والتغليف</h1>
          <div className="product-packing-container">{listItem}</div>
        </div>
        <div className="product-header">
          <h1 className="main-header-packing">منتجات الخلاطات التانكات</h1>
          <div className="product-packing-container">{listItem2}</div>
        </div>
      </div>
    </div>
  );
};

export default MyProducts;
