import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { BsFacebook } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import Hamburger from "hamburger-react";
import { useMyContext } from "../context/MyContext";
const Header = () => {
  const { isOpen, setOpen } = useMyContext();
  return (
    <div className="header">
      <nav className="header-container">
        <ul className="nav-list">
          <li className="nav-list-li">
            <Link to="/" className="nav-list-link">
              الرئيسيه
            </Link>
          </li>
          <li className="nav-list-li">
            <Link to="/products" className="nav-list-link">
              منتجاتنا
            </Link>
          </li>
          <li className="nav-list-li">
            <Link to="/about-us" className="nav-list-link">
              من نحن
            </Link>
          </li>

          <li className="nav-list-li">
            <Link to="/my-work" className="nav-list-link">
              أعمالنا
            </Link>
          </li>
          <li className="nav-list-li">
            <Link to="/contact-us" className="nav-list-link">
              اتصل بنا
            </Link>
          </li>
        </ul>
        <Hamburger
          className="my-hamburger"
          toggled={isOpen}
          toggle={() => {
            setOpen(!isOpen);
            console.log(isOpen);
          }}
        />

        <div className="nav-icon">
          <a
            href="https://www.facebook.com/%D9%85%D9%85%D8%AD%D9%85%D9%88%D8%AF-%D8%A7%D9%84%D9%82%D8%A7%D8%B6%D9%8A-%D9%84%D8%B5%D9%8A%D8%A7%D9%86%D9%87-%D8%AC%D9%85%D9%8A%D8%B9-%D9%85%D8%A7%D9%83%D9%8A%D9%86%D8%A7%D8%AA-%D8%A7%D9%84%D8%AA%D8%B9%D8%A8%D8%A6%D9%87-%D9%88%D8%A7%D9%84%D8%AA%D8%BA%D9%84%D9%8A%D9%81-288872664459828"
            target="_blank"
          >
            <BsFacebook />
          </a>
          <a
            href="https://www.youtube.com/channel/UCxIT6N5HQzytgUmAnlxuoIw/featured"
            target="_blank"
          >
            <BsYoutube />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Header;
