import React from "react";
import landing from "../assets/Artboard 1.png";
import landing2 from "../assets/landing 2.png";
import lastLand from "../assets/lastLand.png";
import "./Landing.css";
const Landing = () => {
  return (
    <div className="my-landing">
      <img src={lastLand} alt="landing" />
    </div>
  );
};

export default Landing;
