import React from "react";
import Company from "../../assets/comapny.jpg";
import "./AboutCompany.css";
const AboutCompany = () => {
  return (
    <div className="com-about-me">
      <div className="container com-container">
        <div className="com-div">
          <h5>عن الشركة</h5>
          <p className="com-p-title">
            نحن شركة تعبئة و تغليف مصرية, تعمل طبقًا لاستراتيجية متكاملة تشمل
            التعبئة، واحتياجات المصانع والمطاعم . واستطاعنا في ستارك في سنوات
            قليلة أن نحجز لنا مكانة رفيعة المستوى في سوق التعبئة والتغليف فمنذ
            بدايتناونحن نتخذ منهجًا واضحًا نضعه نصب أعيننا، ونسير بخطة متطورة
            أهلتنا للوصول لتلك المكانة. حيث نعمل دائمًا على ضمان الشفافية،
            والاحترافية، ومستوى عالٍ من الجودة كي ننال ثقة عملائنا.
          </p>
          <p className="com-p-welcome">
            وكذلك متجرنا الإلكتروني الذي تتسوق فيه الآن، والذي يخضع بشكل كامل
            تحت إدارتنا "ستارك للتعبئة والتغليف"، اتخذ نفس النهج الواضح لشركتنا،
            وسلك نفس مسلكها من ضمان الشفافية، والاحترافية، ومستوى عالٍ من
            الجودة. تجمع ستارك بين التكنولوجيا وأفضل أنظمة التوزيع الاستراتيجية،
            التي تفوق التوقعات فيما يخص أحدث منتجات التعبئة، وأعلى جودة مدعمة
            بالإبداع والابتكار بصورة فائقة، وندرك أن أدائنا السابق ليس ضمانا
            للنجاح. ونؤكد على أهمية إنشاء أفضل معايير الجودة في الصناعة.
          </p>
          <p className="com-p-last">
            نضمن ستارك التميز لكل عملائنا، من خلال تقدير واحترام تفرد كل علامة
            تجارية لأى مؤسسة نعمل معها، مما يلبي احتياجات المؤسسة، ويتم ذلك
            بواسطة تقديم حلول متكاملة للتعبئة و التغليف، علاوةً على مجموعة
            منتجاتنا المتنوعة{" "}
          </p>
          <p className="com-p-last">
            لذلك تمكنت ستارك من مد جسور الثقة بينها وبين عملائها
          </p>
        </div>
        <div className="com-img">
          <img src={Company} alt="Welcome" />
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
